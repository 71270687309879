import base from './Base';

var Modal = (function () {
  'use strict';

  /* Variable inits */
  const openedModals = [];
//  const entryCheckbox = base.get('.hcpEntry');
  const entryModal = base.get('.entry-modal');
  const takedaModal = base.get('.takeda-modal');
  const externalModal = base.get('.external-modal');
  const downloadModal = base.get('.download-modal');
  const modalContainer = base.get('.modal-container');
  

  /* Handles Closing Modal */
  const closeModal = () => {
    let currentOpened = openedModals[0];
    base.removeClass(currentOpened, 'open');
    currentOpened.setAttribute('tabindex', '');

    const session = currentOpened.getAttribute('data-session');
    if (session) { sessionStorage.setItem(session, true); }

    if (openedModals.length < 2) { base.removeClass(modalContainer, 'open'); }
    openedModals.shift();
    openedModals[0].focus();
  };

  /* Handles Opening Modal */
  const openModal = (elem, link) => {
    openedModals.unshift(elem);
    if (link) { elem.querySelector('.contiune').setAttribute('href', link); }

    base.addClass(elem, 'open');
    base.addClass(modalContainer, 'open');

    elem.setAttribute('tabindex', '-1');
    elem.focus();
  };

  /* Handles Link Redirect and Modal Close */
  const redirectLink = (elem) => {
    const link = elem.getAttribute('href');
    window.open(link, '_blank');
    closeModal();
  };

  /* Open Entry Modal if Needed */
    const onEntryModal = () => {
        var user = getCookie("hcpEntry");
        var IsfromIntCmvtransplant = getCookie("IsfromUSCmvtransplant");
        if (user == "" && IsfromIntCmvtransplant == "") {
              base.get('.modal-backdrop').setAttribute('data-modal', '');
              openModal(entryModal);
          }
  };

  /* Update Entry Based on Checkbox */
  //const updateEntry = (e) => {
  //  const elem = e.target;
  //  if (elem.checked) {
  //    entryModal.querySelector('.btn-primary').disabled = false;
  //    base.get('.modal-backdrop').setAttribute('data-modal', 'close');
  //  } else {
  //    entryModal.querySelector('.btn-primary').disabled = true;
  //    base.get('.modal-backdrop').setAttribute('data-modal', '');
  //  }
      
  //};

  /* Handles Click Event to Find Correct Modal to Open or Close */
  const getModalOpenClose = (e) => {

    const elem = base.propagateUpLink(e.target);
    const link = elem.getAttribute('href');
    const modal = elem.getAttribute('data-modal');
    
    if (!modal) { return false; }
    e.preventDefault();

    switch (modal) {
      case 'external':
        openModal(externalModal, link);
        break;
      case 'takeda':
        openModal(takedaModal, link);
        break;
      case 'download':
        openModal(downloadModal);
        break;
      case 'contiune':
        redirectLink(elem);
        break;
      case 'close':
        closeModal(elem);
        break;
    }
  };

  const handleEvents = () => {
    window.addEventListener('click', (e) => { getModalOpenClose(e); });


    //entryCheckbox.addEventListener('change', (e) => { updateEntry(e); });

  };


  function init() {
    handleEvents();
    onEntryModal();
  }
    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
  return {
    init: init
  };
}());

export default Modal;
