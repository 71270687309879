import nav from './Nav';
import base from './Base';
import modal from './Modal';
import animate from './Animate';
import stickyHeader from './StickyHeader';
import scrollToContent from './ScrollToContent';


(function () {
  var pageLoadFunctions = {
    /* Initialize methods on page load */
    init: function () {
      nav.init();
      modal.init();
      stickyHeader.init();
      scrollToContent.init();
      base.pageHasSelector(animate.init, '.animate-this');
    }
  };
  document.onreadystatechange = function () {
    if (document.readyState === 'complete') {
      pageLoadFunctions.init();
    }
      if(window.location.search.indexOf("Id=1")>-1)
      {
          document.cookie = "IsfromUSCmvtransplant=true;";
          window.history.pushState({}, "", window.location.href.split("?")[0]);
      }
  };
})();