import base from './Base';

var Nav = (function () {

  const body = base.get('body');
  const mobileToggle = base.get('.mobile-nav');

  const toggleNav = () => {
    base.toggleClass(body, 'mobile-nav-active');
  };

  const resetNav = base.debounce(function () {
    base.removeClass(body, 'mobile-nav-active');
  }, 100);

  const handleEvents = () => {
    mobileToggle.addEventListener('click', () => { toggleNav(); });

    window.addEventListener('resize', () => { resetNav(); });
  };

  const init = () => {
    handleEvents();
  };

  return {
    init: init
  };

}());

export default Nav;
