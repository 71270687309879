import base from './Base';

var StickyHeader = (function () {
  'use strict';

  /* Variable inits */
  const body = base.get('body');
  let ticking = false;
  let lastScrollPos = window.pageYOffset;

    

  /* Checks Scroll and Sets Class To Body */
    const checkScrollPos = (scroll_pos) => {       
    if (scroll_pos > 120) {
      body.classList.add('sticky-header');
    } else {
      body.classList.remove('sticky-header');
    }
  };

  /* Gets Last Y Scroll Position from Top of Page */
  const lastKnown = () => {
      lastScrollPos = window.pageYOffset;
    if (!ticking) {
      window.requestAnimationFrame(function () {
        checkScrollPos(lastScrollPos);
        ticking = false;
      });

      ticking = true;
    }
    };

    const checkSticky = () => {
        lastScrollPos = window.pageYOffset;
        checkScrollPos(lastScrollPos);
    };           

  const handleEvents = () => {
      window.addEventListener('scroll', () => { lastKnown(); });
      window.addEventListener('load', () => { checkSticky(); });
  };

  function init() {
    handleEvents();
  }

  return {
    init: init
  };
}());

export default StickyHeader;
