var Base = (function () {

  const storage = {
    scrollToStore: {
      validation: false,
      speeds: {
        slow: .035,
        normal: .05,
        fast: .065
      }
    }
  };

  function select(selector) {
    return Array.prototype.slice.call(document.querySelectorAll(selector));
  }

  function selectAllClasses(selector) {
    return document.querySelectorAll('.' + selector);
  }

  function get(selector) {
    return document.querySelector(selector);
  }

  function getAll(elem) {
    return Array.from(document.querySelectorAll(elem));
  }

  function hasClass(element, cls) {
    return element.classList.contains(cls);
  }

  function isInViewport(elem) {
    var bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  function addClass(element, cls) {
    if (element != null) { element.classList.add(cls); }
  }

  function removeClass(element, cls) {
    if (element != null) { element.classList.remove(cls); }
  }

  function toggleClass(elem, cls) {
    if (!elem) { throw new Error('Requires element'); }
    if (!cls) { throw new Error('Requires class name'); }
    elem.classList.toggle(cls);
  }

  function findClosest(elem, cls) {
    while ((elem = elem.parentElement) && !elem.classList.contains(cls));
    return elem;
  }

  function findChild(elem, selector) {
    if (!elem) { throw new Error('Requires element'); }
    if (!selector) { throw new Error('Requires selector'); }
    if (elem.querySelector(selector)) {
      return elem.querySelector(selector);
    } else {
      throw new Error('Child element "' + selector + '" does not exist in parent');
    }
  }

  function propagateUpLink(elem) {
    if (!elem) { throw new Error('Requires element'); }
    if (elem.tagName != "A" && elem.parentNode.tagName == "A") {
      elem = elem.parentNode;
    }

    return elem;
  }

  function pageHasSelector() {
    const fn = arguments[0];
    if (typeof (fn) != 'function') { throw new Error('Requires action'); }

    for (let i = 1; i < arguments.length; i++) {
      if (document.querySelector(arguments[i])) { return fn(); }
    }
  }

  function debounce(func, wait, immediate) {
    let timeout;
    return function () {
      const context = this, args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  function scrollTo(config) {
    const { element, speed = 'normal', offset = 0, startY = Math.round(window.pageYOffset) } = config;
    const { scrollToStore } = storage;

    if (!scrollToStore.validation) {
      if (!element) { throw new Error('Requires valid element'); }
      if (!Object.keys(scrollToStore.speeds).includes(speed)) { throw new Error('Requires valid speed: slow, normal, fast'); }
      storage.scrollToStore.validation = true;
    }

    const endY = isNaN(element) ? element.offsetTop : element;
    const setOffset = isNaN(offset) ? offset.offsetHeight : offset;
    const setSpeed = scrollToStore.speeds[speed];
    const calcMovement = Math.ceil(Math.abs(startY - endY) * setSpeed);

    if (startY != endY) {
      setTimeout(() => {
        window.scrollTo(0, startY - setOffset);
        scrollTo({
          element: endY,
          speed: speed,
          offset: setOffset,
          startY: startY < endY ? startY + calcMovement : startY - calcMovement
        });
      }, 10);
    } else {
      storage.scrollToStore.validation = false;
    }
  }

  return {
    get: get,
    getAll: getAll,
    hasClass: hasClass,
    addClass: addClass,
    removeClass: removeClass,
    toggleClass: toggleClass,
    findClosest: findClosest,
    findChild: findChild,
    select: select,
    debounce: debounce,
    scrollTo: scrollTo,
    isInViewport: isInViewport,
    propagateUpLink: propagateUpLink,
    selectAllClasses: selectAllClasses,
    pageHasSelector: pageHasSelector,
    getBodyClass: function () {
      return document.getElementsByTagName('body')[0].classList.toString();
    },
    getSingularClass: function (className) {
      return document.getElementsByClassName(className)[0];
    }
  };
}());

export default Base;