import base from './Base';

var ScrollToContent = (function () {
  'use strict';
  const anchorElems = base.getAll('[data-scroll]');

  const setUpScroll = (e, elem) => {
    e.preventDefault();

    const body = base.get('body');
    const page = elem.getAttribute('href');
    const anchor = elem.getAttribute('data-scroll');

    base.removeClass(body, 'mobile-nav-active');

    if (page != '#' && page != window.location.pathname) {
      window.location.replace(page + '#' + anchor );
    } else {
      base.scrollTo({
        element: document.getElementById(anchor)
      });
    }
  };

  const handleEvents = () => {
    anchorElems.map((anchor) => {
      anchor.addEventListener('click', (e) => { setUpScroll(e, anchor); });
    });
  };


  function init() {
    handleEvents();
  }

  return {
    init: init
  };
}());

export default ScrollToContent;
