import base from './Base';

var Animate = (function () {
  'use strict';

  /* Variable inits */
  const animateElems = base.getAll('.animate-this')

  /* Checks if Elements are in Viewport and Adds Class */
  const checkForAnimation = base.debounce(() => {
    if (animateElems.length < 1) { return false; }
    animateElems.map((elem) => {
      if (base.isInViewport(elem) && !base.hasClass(elem, 'animate-now')) {
        const chart = elem.getAttribute('data-chart');
        base.addClass(elem, 'animate-now');

        if (chart == null) { return false; }
        window.setTimeout(() => {
          elem.querySelector('img').src = '../dist/images/charts/chart-' + chart + '.gif';
        }, 300);
      }
    })
  }, 15);
  
  /* Handles Events */
  const handleEvents = () => {
    window.addEventListener('scroll', () => { checkForAnimation(); });
  };

  function init() {
    handleEvents();
    checkForAnimation();
  }

  return {
    init: init
  };
}());

export default Animate;
